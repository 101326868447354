<template>
  <div class="compliance">
    <complianceHero />
    <div class="container">
      <complianceAppeal />
      <!-- <complianceEthics /> -->
      <complianceAbout />
      <complianceSpeak />
      <complianceForm id="#compliance-form" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ComplianceService",
  components: {
    ComplianceHero: () =>
      import("@/components/pages/compliance/ComplianceHero.vue"),
    ComplianceAppeal: () =>
      import("@/components/pages/compliance/ComplianceAppeal.vue"),
    // ComplianceEthics: () =>
    //   import("@/components/pages/compliance/ComplianceEthics.vue"),
    ComplianceAbout: () =>
      import("@/components/pages/compliance/ComplianceAbout.vue"),
    ComplianceSpeak: () =>
      import("@/components/pages/compliance/ComplianceSpeak.vue"),
    ComplianceForm: () =>
      import("@/components/pages/compliance/ComplianceForm.vue"),
  },
};
</script>
